import { createStore } from "vuex";

export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    lang: localStorage.getItem("lang") || "ru",
    status: 1,
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setLang(state, lang) {
      state.lang = lang;
      localStorage.setItem("lang", lang);
    },
    setStatus(state, status) {
      state.status = status;
    },
  },
  actions: {},
  modules: {},
});
