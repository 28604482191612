<template>
  <div class="section__home">
    <div class="section__home__content">
      <div class="section__row">
        <div class="section__home__info">
          <div class="section__home__info__title">
            {{ $t("home__title") }}
          </div>
          <div class="section__home__info__text">
            {{ $t("home__text") }}
          </div>
          <button @click="next()" class="section__home__info__action">
            {{ $t("home__action") }}
          </button>
        </div>
        <div class="section__home__img">
          <img :src="posterImage" alt="Poster" />
          <img
            style="display: none"
            src="@/assets/img/home-bg-kz.png"
            alt="Poster" />
          <img
            style="display: none"
            src="@/assets/img/home-bg-ru.png"
            alt="Poster" />
        </div>
      </div>
    </div>
  </div>

  <img src="" alt="" />
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      lang: "",
      auth: "",
      kzImage: "/img/home-bg-kz.3535c0f8.png",
      ruImage: "/img/home-bg-ru.9c8a6c59.png",
    };
  },
  mounted() {
    this.auth = localStorage.getItem("token");
    this.lang = localStorage.getItem("currentLanguage");
    localStorage.setItem("status", 1);
  },
  methods: {
    next() {
      if (this.auth) {
        this.$router.push("/main");
      } else {
        this.$router.push("/login");
      }
    },
  },
  computed: {
    posterImage() {
      if (this.language === "kz") return this.kzImage;
      return this.ruImage;
    },
    language() {
      return this.$store.state.lang;
    },
  },
};
</script>
