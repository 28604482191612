import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import ru from "./locales/ru.json";
import kz from "./locales/kz.json";

const messages = {
  ru,
  kz,
};

const i18n = createI18n({
  locale: "ru",
  fallbackLocale: "ru",
  messages,
});

import "@/styles/main.scss";

// Vuetify
import { VDataTable } from "vuetify/labs/VDataTable";

import "@mdi/font/css/materialdesignicons.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(vuetify)
  .component("VDataTable", VDataTable)
  .mount("#app");
