import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    component: () => import("@/layout/DefaultComponent.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
      },
      {
        path: "/main",
        name: "main",
        component: () => import("../views/MainView.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/search",
        name: "search",
        component: () => import("../views/main/SearchView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/give-out",
        name: "give-out",
        component: () => import("../views/main/GiveOutView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/give-out/:id",
        component: () => import("../views/main/GiveStudent.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/received",
        name: "received",
        component: () => import("../views/main/ReceivedInView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/received/:id",
        component: () => import("../views/main/ReceiveStudent.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/finish",
        component: () => import("../views/FinishView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: "/student",
    name: "student",
    component: () => import("@/layout/GeneralComponent.vue"),
    children: [
      {
        path: "/student/side",
        name: "side",
        component: () => import("../views/StudentSideView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const token = localStorage.getItem("token");

  if (requiresAuth && !token) {
    next("/login"); // Redirect to login if not authenticated
  } else {
    next();
  }
});

export default router;
